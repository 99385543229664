<template>
  <div>
    <licensee-details />
    <style-guide-details />
    <character-details />
    <article-details />
    <channel-details />
    <territory-details />
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted } from 'vue';
import useDealSummary from './dealSummary.js';

export default {
    name: 'BrandAssuranceDealSummary',

    components: {
        ArticleDetails: defineAsyncComponent(() => import('./ArticleDetails.vue')),
        ChannelDetails: defineAsyncComponent(() => import('./ChannelDetails.vue')),
        CharacterDetails: defineAsyncComponent(() => import('./CharacterDetails.vue')),
        LicenseeDetails: defineAsyncComponent(() => import('./LicenseeDetails.vue')),
        StyleGuideDetails: defineAsyncComponent(() => import('./StyleGuideDetails.vue')),
        TerritoryDetails: defineAsyncComponent(() => import('./TerritoryDetails.vue'))
    },

    setup () {
        const { dealSummaryData } = useDealSummary();

        const initDealSummary = () => {};
        onMounted(() => {
            initDealSummary();
        });

        return {
            dealSummaryData,
            initDealSummary
        };
    }
};
</script>
